<template>
  <div>
    <el-card style="min-height: 45rem">
      <el-table :data="list" border>
        <el-table-column prop="id" width="80" label="编号"></el-table-column>
        <el-table-column prop="title" label="标题"></el-table-column>
        <el-table-column prop="pic" label="图片">
          <template v-slot="s">
            <img :src="s.row.pic | tomedia" style="width: 3rem;height: 3rem;border-radius: 3px" alt="">
          </template>
        </el-table-column>
        <el-table-column prop="file" label="文件">
          <template v-slot="s">
            <span class="y-pointer" @click="toFilePreview(s.row.file)">{{s.row.file}}</span>
          </template>
        </el-table-column>
        <el-table-column prop="link_path" label="链接"></el-table-column>
        <el-table-column prop="link_title" label="链接标题"></el-table-column>
        <el-table-column prop="link_pic" label="链接图标">
          <template v-slot="s">
            <img :src="s.row.link_pic | tomedia" style="width: 3rem;height: 3rem;border-radius: 3px" alt="">
          </template>
        </el-table-column>
        <el-table-column label="操作" align="center">
          <template #header>
            <el-button @click="editFormBox=true" type="primary">添加视频</el-button>
          </template>
          <template #default="s">
            <el-button @click="editItem(s.row)" type="primary">编辑</el-button>
            <el-button @click="del(s.row)" type="danger">删除</el-button>
          </template>
        </el-table-column>
        <template v-slot:append>
          <el-pagination style="padding: .5rem" background layout="total,prev, pager,next"
                         :total="total" :page-size="size" :current-page="page" @current-change="pageChange">
          </el-pagination>
        </template>
      </el-table>
      <el-dialog title="编辑视频" :visible.sync="editFormBox" @close="editFormClose">
        <el-form label-width="5rem" style="padding: 0 2rem">
          <el-form-item label="标题">
            <el-input v-model="form.title"></el-input>
          </el-form-item>
          <el-form-item label="图片">
            <YUploadImg v-model="form.pic"></YUploadImg>
          </el-form-item>
          <el-form-item label="视频">
            <y-upload-video v-model="form.file"></y-upload-video>
          </el-form-item>
          <el-form-item label="链接路径">
            <y-choose-link @title-change="linkTitleChange" @pic-change="linkPicChange" v-model="form.link_path"></y-choose-link>
          </el-form-item>
          <el-form-item label="链接标题">
            <el-input v-model="form.link_title"></el-input>
          </el-form-item>
          <el-form-item label="链接图标">
            <y-upload-img v-model="form.link_pic"></y-upload-img>
          </el-form-item>
        </el-form>
        <div slot="footer" class="dialog-footer">
          <el-button @click="editFormBox = false">取 消</el-button>
          <el-button type="primary" @click="edit">确 定</el-button>
        </div>
      </el-dialog>
    </el-card>
  </div>
</template>

<script>

import YUploadImg from "@/components/y-form/y-upload/y_upload_img";
import YUploadVideo from "@/components/y-form/y-upload/y_upload_video";
import YChooseLink from "@/components/shop/y_choose_link";
export default {
  name: "manage",
  components:{
    YChooseLink,
    YUploadVideo,
    YUploadImg
  },
  data() {
    return {
      list:[],
      page:1,
      total:0,
      size:0,
      form:{
        title:"",
        pic:"",
        file:"",
        link_path:"",
        link_title:"",
        link_pic:"",
      },
      editFormBox:false,
    }
  },
  mounted() {
    this.load();
  },
  methods: {
    linkTitleChange(e){
      this.form.link_title = e;
    },
    linkPicChange(e){
      this.form.link_pic = e;
    },
    toFilePreview(file){
      let uri = this.$store.getters.activeUniacidAttachmentUrl + file;
      window.open(uri,"_blank");
    },
    del({id}){
      this.$u.api.album.videoDel({id}).then(()=>{
        this.$message.success("操作成功");
        this.editFormBox = false;
        this.load();
      })
    },
    editItem(item){
      this.form = {...item};
      this.editFormBox = true;
    },
    editFormClose(){
      this.form = {
        title:"",
        pic:"",
        file:"",
        link_path:"",
        link_title:"",
      }
      this.editFormBox = false;
    },
    load() {
      this.$u.api.album.videoSearch({page:this.page}).then(res=>{
        this.list = res.list;
        this.total = res.total;
        this.size = res.size;
      })
    },
    edit(){
      this.$u.api.album.videoEdit(this.form).then(()=>{
        this.$message.success("操作成功");
        this.editFormBox = false;
        this.load();
      })
    },
    pageChange(e){
      this.page = e;
      this.load();
    }
  }
}
</script>

<style scoped>

</style>