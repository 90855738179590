<template>
  <div>
    <el-input disabled size="mini" placeholder="请选择链接" :value="value">
      <template slot="append">
        <div @click="dialogVisible=true" class="y-pointer">选择链接</div>
      </template>
    </el-input>
    <el-dialog custom-class="custom" append-to-body title="选择链接" :show-close="false" :visible.sync="dialogVisible" width="50rem">
      <el-tabs v-model="activeName" style="height: 20rem">
        <el-tab-pane label="系统链接" name="system">
          <template v-for="(item,key) in system">
            <el-button style="margin: .5rem" @click="chooseSys(key)" :key="key">{{item.title}}</el-button>
          </template>
        </el-tab-pane>
        <el-tab-pane label="自定义商品专题页" name="theme">
          <template v-for="(item,key) in theme">
            <el-button @click="chooseTheme(key)" :key="key">{{item.title}}</el-button>
          </template>
        </el-tab-pane>
        <el-tab-pane label="商城商品" name="goods">
          <template v-for="(item,key) in goods">
            <el-button @click="chooseGoods(key)" :key="key">{{item.title}}</el-button>
          </template>
        </el-tab-pane>
        <el-tab-pane label="画册" name="album">
          <template v-for="(item,key) in album">
            <el-button @click="chooseAlbum(key)" :key="key">{{item.title}}</el-button>
          </template>
        </el-tab-pane>
        <el-tab-pane v-if="useCustom" label="自定义链接" name="custom">
          <div class="flex-def flex-zCenter flex-cCenter" style="height: 10rem">
            <el-input style="width: 30rem" placeholder="请输入" v-model="custom">
              <template slot="append">
                <el-button @click="sureCustom" style="padding:0 3rem;" size="mini">确认</el-button>
              </template>
            </el-input>
          </div>
        </el-tab-pane>
      </el-tabs>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: "yChooseLink",
  model:{
    prop:"modelval",
    event:'change'
  },
  props:{
    useCustom:{
      type:Boolean,
      default: true
    },
    modelval: {
      type:String,
      default:""
    },
  },
  watch:{
    modelval(){
      this.value = this.modelval;
    },
    value(e){
      this.$emit('change',e);
    }
  },
  data(){
    return{
      value: this.modelval,
      custom:"",
      dialogVisible:false,
      activeName:"system",
      system:[
        {title:"首页",link:"/pages/index/index"},
        {title:"个人中心页",link:"/pages/user/index"},

        {title:"商城入口页",link:"/pages/shop/goods/theme"},
        {title:"商城购物车页",link:"/pages/shop/cashier/cart"},
        {title:"商城邀请好友页",link:"/pages/shop/user/invite"},
        {title:"商品列表页",link:"/pages/shop/goods/search"},


        {title:"画册列表页",link:"/pages/album/list"},
        {title:"画册文章列表页",link:"/pages/album/article/list"},
        {title:"画册视频页",link:"/pages/album/video"},
        {title:"画册视频列表页",link:"/pages/album/video-list"},
        {title:"画册全部文章",link:"/pages/album/article/list"},
      ],
      theme:[],

      goods:[],

      album:[],
    }
  },
  mounted() {
    this.loadTheme();
    this.loadGoods();
    this.loadAlbum();
  },
  methods:{
    loadAlbum(){
      this.$u.api.album.search({page:-1}).then(res=>{
        this.album = res.list;
      })
    },
    chooseAlbum(key){
      let item = this.album[key];
      this.value = `/pages/album/detail?id=${item.id}`;
      this.$emit('title-change',item.title);
      this.$emit('pic-change',item.pic);
      this.dialogVisible = false;
    },
    loadGoods(){
      this.$u.api.shop.goods.search({page:-1}).then(res=>{
        this.goods = res.list;
      })
    },
    chooseGoods(key){
      let item = this.goods[key];
      this.value = `/pages/shop/goods/detail?id=${item.id}`;
      this.$emit('title-change',item.title);
      this.$emit('pic-change',item.pic);
      this.dialogVisible = false;
    },
    chooseTheme(key){
      let item = this.theme[key];
      this.value = `/pages/shop/goods/theme?tid=${item.id}`;
      this.dialogVisible = false;
    },
    loadTheme(){
      this.$u.api.shop.goods.themeSearch({page:-1}).then(res=>{
        this.theme = res.list;
      })
    },
    sureCustom(){
      this.dialogVisible=false;
      this.value = this.custom;
    },
    chooseSys(key){
      let item = this.system[key];
      this.value = item.link;
      this.dialogVisible = false;
    },
  }
}
</script>
<style>
.custom>.el-dialog__body{
  padding: 0 20px 30px !important;
  margin: 0;
}
</style>
<style scoped>

</style>